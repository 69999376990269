
import { Options, Vue } from 'vue-class-component';
import AboutUsHero from '@/components/AboutUsHero.vue';
import FindOutAboutUS from '@/components/FindOutAboutUS.vue';
import Team from '@/components/Team.vue';

@Options({
  components: {
    AboutUsHero,
    FindOutAboutUS,
    Team,
  },
})
export default class About extends Vue {}
